<template>
  <div class="main">
    <div class="banner"></div>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 450px;
  background: url("../assets/img/news/banner_news.jpg") no-repeat;
  background-size: 2560px 450px;
  background-position: center;
}
.content{
    padding:50px 0;
}
</style>